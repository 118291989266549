<template>
  <div class="report-view skill-report pb-5">
    <div class="viewEdit ttrInfo">

      <!-- start date picker toggle -->
      <b-card class="d-flex">
        <!-- Resource Allocation by Skills Report, Calander -->
        <div class="calendar justify-content-between mt-0 custom-width">
          <p class="task-timesheet">
            Project Health Status
          </p>
        </div>
      </b-card>
      <!-- end date picker toggle -->

      <!-- start Filters & export -->
      <div class="card-filter-section">
        <div class="d-flex align-items-center flex-wrap justify-content-between">
          <div class="d-flex align-items-center justify-content-start flex-wrap">
            <p class="filter-title">
              Filters:
            </p>

            <div class="">
              <custom-dropdown
                v-model="status"
                :options="projectStatusList"
                :search-box="false"
                label="Status"
                icon="EditIcon"
                multiple
              />
            </div>
            <OptionDropdown v-model="perPage" />
          </div>
          <div class="d-flex align-items-center">
            <b-input-group
              class="input-group-merge search-project"
              style="min-width: 250px; max-width: 250px; width: 250px"
            >
              <b-form-input
                v-model="debouncedSearch"
                placeholder="Search by Project Name"
                @keyup.13="applyFilter"
              />

              <b-input-group-append is-text>
                <feather-icon
                  v-if="search"
                  icon="XIcon"
                  @click="clearFilter()"
                />
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
            <div class="ml-1">
              <button
                class="apply-filter filterRedesign mr-1"
                @click="applyFilter()"
              >
                Apply Filter
              </button>
              <button
                class="apply-filter-clear filterRedesign"
                @click="clearFilter()"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end Filters & export -->

      <div class="report-task-section">
        <table-loader
          v-if="loader"
          :fields="fields"
        />
        <div v-else-if="projectListingData.length">
          <div class="tableDesigns ">
            <b-table
              :fields="fields"
              :items="projectListingData"
              hover
              sticky-header
              class="table-font-color"
            >
              <template #cell(name)="data">
                <div
                  class="d-flex align-items-center"
                  @click="healthReportDetail(data.item.id)"
                >
                  <div>
                    <b-avatar
                      :text="data.item.name | avatarText"
                      :style="{ 'background-color': '#' + data.item.color }"
                      size="35"
                    />
                  </div>
                  <div
                    class="media-body cursor-pointer"
                  >
                    <p
                      class="project-name-bold"
                      style="margin-left: 5px;font-size:14px;"
                      :style="{ color: '#' + data.item.color }"
                    >
                      {{ data.item.name }}
                    </p>
                  </div>
                </div>
              </template>
              <template #cell(last_status)="data">

                {{ data.item.project_health ? data.item.project_health : '-' }}
              </template>
              <template #cell(action)="data">
                <span class="action-edit-del cursor-pointer">
                  <feather-icon
                    icon="ArrowRightCircleIcon"
                    color="#2178fb"
                    size="20"
                    @click="healthReportDetail(data.item.id)"
                  />
                </span>
              </template>

              <template
                slot="bottom-row"
                slot-scope
              >
                <td
                  colspan="4"
                  style="padding:8px; cursor:default;"
                >
                  <Pagination
                    v-if="totalCount > 0"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :total-count="totalCount"
                    @currentPage="currentPage = $event"
                  />
                </td>
              </template>
            </b-table>
          </div>

        </div>
        <div
          v-else
          class="w-100"
        >
          <img
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VBToggle, BTable } from 'bootstrap-vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import debounce from 'lodash/debounce'
import OptionDropdown from '../../optiondropdown/OptionDropdown.vue'

export default {
  name: 'HeaderBlock',
  components: {
    customDropdown,
    BTable,
    OptionDropdown,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Project Name',
        },
        {
          key: 'last_status',
          label: 'Latest Project Health Status',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'unreviewed_reports_count',
          label: 'Pending to review',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          thClass: 'text-center',
          tdClass: 'text-center',
        },

      ],
      projectListingData: [],

      status: ['in_planning', 'in_progress'],

      search: null,
      totalCount: 0,
      currentPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      perPage: this.$route.query.per_page ? parseInt(this.$route.query.per_page) : 25,
      sort: 'asc',
      loader: false,
    }
  },
  computed: {
    budgetList() {
      return [
        { value: 'over_budget', label: 'Over Budget Projects' },
        { value: 'under_budget', label: 'Under Budget Projects' },
      ]
    },

    clientListOption() {
      return this.$store.state.app.clientList.map(element => ({
        value: element.id,
        label: element.name,
      }))
    },

    validateFilter() {
      return false
    },

    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.projectList()
        this.updateQueryParams()
      }, 750),
    },
  },
  watch: {
    status() {
      this.updateQueryParams()
    },
    perPage() {
      this.currentPage = 1
      this.projectList()
      this.updateQueryParams()
    },
    currentPage() {
      this.projectList()
      this.updateQueryParams()
    },
  },
  mounted() {
    const { query } = this.$route

    // Initialize filter values from route query parameters
    this.type = query.type ? query.type.split(',') : []
    this.status = query.status ? query.status.split(',') : ['in_planning', 'in_progress']
    this.budget = query.budget || null
    this.user = query.user || null
    this.client_id = query.client_id || null
    this.search = query.search || null
    if (this.userInfo && this.userInfo.role == 'SA') {
      this.clientList()
      if (!this.$store.state.app.usersList.length) {
        this.userList()
      }
    }

    this.projectList()
  },
  methods: {

    updateQueryParams() {
      const query = this.getQueryParams()
      // Push the query parameters to the route
      this.routeQueryUpdate(query)
    },
    getQueryParams() {
      const query = {
        type: this.type.length ? this.type.join(',') : null,
        status: this.status.length ? this.status.join(',') : null,
        budget: this.budget || null,
        user: this.user || null,
        client_id: this.client_id || null,
        search: this.search || null,
        page: this.currentPage,
        per_page: this.perPage,
      }

      // Filter out null or empty values from the query object
      return Object.keys(query).reduce((acc, key) => {
        if (query[key]) acc[key] = query[key]
        return acc
      }, {})
    },
    async applyFilter() {
      this.currentPage = 1
      await this.projectList()
      this.updateQueryParams()
    },

    clearFilter() {
      this.type = []
      this.status = ['in_planning', 'in_progress']
      this.budget = null
      this.user = null
      this.client_id = null
      this.search = null
      this.currentPage = 1
      this.perPage = 25
      this.projectList()
      this.updateQueryParams()
    },

    healthReportDetail(id) {
      const { query } = this.$route

      this.$router.push({
        name: 'projectHealthReports',
        query: {
          ...query,
          project_id: id,
        },
      })
    },
    async projectList() {
      this.loader = true
      const input = {
        search: this.search,
        status: this.status,
        user_id: this.user,
        type: this.type,
        budget: this.budget,
        page: this.currentPage || 1,
        per_page: this.perPage || 25,
        client_id: this.client_id,
        sort_order: this.sort || 'asc',
        sort_field: 'name',
      }
      const response = await this.getHTTPPostResponse(
        'project/project-list-with-health-report',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.projectListingData = data.projects.map(project => ({
          ...project, // Copy all properties from the project object
          project_health: project.latest_health_report && project.latest_health_report.project_health
            ? this.projectHealthRating.find(rat => rat.value == project.latest_health_report.project_health)?.name || null
            : null, // Assign the corresponding name or null based on the conditions
        }))

        this.totalCount = data.count
      }
      this.loader = false
    },
  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/component-css/vieweditheader.scss";
@import "../../../assets/scss/component-css/approvals.css";
@import "../../../assets/scss/component-css/report.css";

.report-view.skill-report table.table tr th {
  padding: 16px;
  background: #f3f2f7;
  border-bottom: 0 !important;
  top: -2px;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: calc(100vh - 190px);
  overflow: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      background-color: transparent;
      border-radius: 10px;
    }
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
    background-color: $scroller-color;
  }
}

.b-table-bottom-row {
  position: sticky;
  bottom: -1px;
  background-color: #F3F2F7 !important;
  padding: 0;

  td>div {
    padding: 0px !important;

    div {
      margin-bottom: 0rem !important;
      padding: 0px !important;
    }
  }
}

.table-font-color table thead tr th div {
  color: #5e5873 !important;
  font-size: 14px !important;
  font-weight: 600;
}

.report-view.skill-report table.table tr td {
  cursor: default;
}
</style>
