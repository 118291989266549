import { render, staticRenderFns } from "./projectListing.vue?vue&type=template&id=2c85999b&"
import script from "./projectListing.vue?vue&type=script&lang=js&"
export * from "./projectListing.vue?vue&type=script&lang=js&"
import style0 from "./projectListing.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports